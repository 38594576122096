/* FONT FAMILY VARIABLES */
$icon-font-family: 'FontAwesome';

$header-font-family: 'Poppins', sans-serif;
$header-font-weight: 600;
$header-line-height: 115%;

/* COLOR VARIABLES */

$color-white: #ffffff;
$color-green: #51bba0;
$color-green-transparent: #51bba065;
$color-orange: #ff9611;
$color-pink: #ff78ed;
$color-yellow: #fff500;
$color-red: #f54d05;
$color-dark-red: #a51b1b;
$color-violet: #767bff;
$color-grey-dark: #666666;
$color-grey-light: #999999;
$color-grey: #888888;

/* EXTERNAL APPS COLOR VARIABLES */

$color-hubspot-orange: #d8621d;
$color-whatsapp-green: #25d366;

/* FONT SIZE VARIABLES */

$font-size-seven: 3.583rem; // 57.33px
$font-size-six: 2.986rem; // 47.78px
$font-size-five: 2.488rem; // 39.81px
$font-size-four: 2.074rem; // 33.18px
$font-size-three: 1.728rem; // 27.65px
$font-size-two: 1.44rem; // 23.04px
$font-size-one: 1.2rem; //19.20px
$font-size-base: 1rem; // 16.00px
$font-size-minus-one: 0.833rem; // 13.33px
$font-size-minus-two: 0.694rem; // 11.11px
$font-size-minus-three: 0.579rem; // 9.26px
$font-size-minus-four: 0.482rem; // 7.72px

/* LINE HEIGHT VARIABLES */

/* BREAKPOINTS */

$breakpoint: 1150px;

/* ENTITY VARIABLES */

$color-entity-car: $color-green;
$color-entity-lessee: $color-orange;
$color-entity-driver: $color-pink;
$color-entity-lessor: $color-red;
$color-entity-attachments: $color-red;
$color-entity-contract-data: $color-violet;
$color-entity-account-manager: $color-violet;
