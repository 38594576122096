/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

%header {
    font-family: $font-title-family;
    font-weight: $font-title-weight-regular;
    color: inherit;
    line-height: 1.1;
    margin: 0;
    padding: 0;
}

h1,
.header-1 {
    @extend %header;
    font-size: 2em;
}

h2,
.header-2 {
    @extend %header;
    font-size: 1.4em;
}

h3,
.header-3 {
    @extend %header;
    font-size: 1.2em;
}

h4,
.header-4 {
    @extend %header;
    font-size: 1.1em;
}

h5,
.header-5 {
    @extend %header;
    font-size: 1.05em;
}

h6,
.header-6 {
    @extend %header;
    font-size: 1em;
}
