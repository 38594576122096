@import '_2021';

.mga-input {
    &[type='text'],
    &[type='email'],
    &[type='number'],
    &[type='number'],
    &[type='tel'],
    &[type='datetime'],
    &[type='date'] {
        background: transparent;
        color: inherit;
        font-size: inherit;
        font-family: inherit;
        margin: 0;
        padding: 0.1rem 0.3rem;
        border-style: none;
        border-bottom: 1px solid $color-grey-light;
        transition: border 0.15s ease;
        max-width: 100%;
        width: 200px;

        &:focus {
            outline: none;
            border-bottom-color: $color-green;
        }
    }

    &[type='radio'],
    &[type='checkbox'] {
        color: currentColor;
        display: inline-block;
        appearance: none;
        border: 0.1em solid currentColor;
        width: 1em;
        height: 1em;
        vertical-align: middle;
        position: relative;
        font-size: 1em;
        background-clip: content-box;
        padding: 0.4em;
        transition: padding 0.2s ease, background 0.2s ease;
        background-color: transparent;
        will-change: padding, background, filter;

        &:hover {
            cursor: pointer;
        }

        &:checked {
            padding: 0.12em;
            background-color: currentColor;
        }

        &:disabled {
            filter: grayscale(90%);
        }
    }

    &[type='checkbox'] {
        &:indeterminate {
            padding: 0.3em 0.12em;
            background-color: currentColor;
        }
    }

    &[type='radio'] {
        border-radius: 0.5em;

        &:indeterminate {
            padding: 0.4em;
            background-color: currentColor;
        }
    }

    mga-form-display-value + &:not(:focus) {
        color: transparent;
    }
}
