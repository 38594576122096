* {
    &,
    &:before,
    &:after {
        box-sizing: border-box;
    }
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    font-family: $font-family;
}

body {
    margin: 0;
    background-color: $color-context-background;
}

[hidden] {
    display: none !important;
}
