// Using http://itcss.io/
// files
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,400;1,600&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css');

@import 'variables/_variables';
@import 'base/_base';
@import 'material';

// 2021 theme

@import '../ui/form-input/form-input.directive';
