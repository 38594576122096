$font-family: unquote(
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Segoe UI Symbol"'
);
$font-title-family: unquote('"Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif');

$font-title-weight-medium: 600;
$font-title-weight-regular: normal;

//** Typographic Scale
$font-size-display-4: 112px;
$font-size-display-3: 56px;
$font-size-display-2: 45px;
$font-size-display-1: 34px;
$font-size-headline: 24px;
$font-size-title: 20px;
$font-size-subhead-2: 15px;
$font-size-subhead-1: 15px;
$font-size-body-2: 16px;
$font-size-body-1: 16px;
$font-size-caption: 12px;
$font-size-label: 12px;
$font-size-menu: 13px;
$font-size-button: 14px;
